/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import LogoImg from '../assets/images/logo.png'


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Header(props) {

  let query = useQuery();
  const goToAPP = () =>{
    const ref = query.get('ref');
    if(ref !== undefined)
      window.location.href = `https://app.pdo.finance/#/list?ref=${ref}`;
    else
      window.location.href = 'https://app.pdo.finance/#/list';
  }

  return (

    <HeaderOuter>
      <HeaderR>
        Partner with Us
      </HeaderR>
    </HeaderOuter>

  )

}
const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const HeaderOuter = styled(FlexDiv)`
  justify-content:space-between; padding: 25px 30px; width:100%; position: absolute; top: 0; left: 0; right: 0;
  ${Media.sm}{
    padding:20px 15px;
  }
`;

const HeaderR = styled.div`
  margin-left: auto; font-weight: 700; font-size: 14px; padding: 8px 33px; border: 2px solid #fff; border-radius: 5px; box-shadow: 0 0 10px #fff; cursor: pointer;
  &:hover {background: #fff; color: #000;}
`;
export default Header;
