/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/smartexchange.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import SliderImg01 from '../assets/images/se01.jpg';
import SliderImg02 from '../assets/images/se02.jpg';
import SliderImg03 from '../assets/images/se03.jpg';
import SliderImg04 from '../assets/images/se04.jpg';
import SliderImg05 from '../assets/images/se05.jpg';






function StakingComp() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>06</Gs.Count>
			<h3>What is the purpose of offering farming?</h3>

			<p>Farming exists to reduce sell pressure on projects. By offering APY to holders who lock up their  token through farming, sell pressure is reduced allowing the projects token price to rise.</p>

			<Gs.Hr/>

			<h3 className='red'>Why the current farming incentives broken</h3>
			<p>The current model of farming requires you to essentially give away free token to avoid holders dumping your token. This free token increases the supply creating significantly more sell pressure. Plus, the APY incentive may work for a while, but many times it may simply delay the dumping pressure for another time.</p>
			<p>It's an expensive price to pay to prevent dumping.</p>
            

			<Gs.Hr/>

			<h3 className='green'>Your next-gen farming solution for your community</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>Give your holders access to unlimited APY that doesn't cost you anything.</p>
			<p>When your users self-custody hold your project's token, they will receive APY that comes from SmartExchange's expedite cross-chain service fees. This means that not only do your holders help expedite new users enter your ecosystem,  but they also receive  juicy APY for doing so.</p>
			<p>Save your treasury and keep your token circulation healthy, all while keeping your holders happy with unlimited APY.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://smart.exchange/' target='_blank' className='btn' rel="noreferrer">Join Smart Exchange to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}

export default StakingComp;
