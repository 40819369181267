/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';

import Logo from '../assets/images/logomin.png';
import Tick from '../assets/images/tick.png';
import corner from '../assets/images/corner.png';

function Subscription(props) {
    return (
        <>
            <Header>
                <Link to="/"><img src={Logo} alt='accelerate ventures'/></Link>
            </Header>
            <FloatingEl>
                <p><span>FREE</span>&nbsp;white paper AI bot <i className="fab fa-telegram-plane"></i></p>
            </FloatingEl>
            <Container>
                <PageTop>
                    <h1 data-text="Seeking help? we've got your back">
                    Seeking help?
                    we've got your back</h1>
                    <p>Your project's success is crucial. Don't leave it to chance! if you're unsure of how to proceed, our expert assistance is just a subscription away. We offer three distinct packages designed to match every need and budget—from our free tier with robust community support to our more personalized one-on-one account manager service. Each plan ensures that you have dedicated support at every step, helping you navigate challenges and maximize your project's potential. </p>
                    <p>Choose the right level of service for you and secure your project's success with us</p>
                </PageTop>
                <PricingMain>
                    <div className='pricetab'>
                        <span>PAY WITH</span>
                        <div className='tabMain'>
                            <a>USD</a>
                            <a className='active'>USDT</a>
                        </div>
                    </div>
                    <div className='pricemain'>
                        <PriceCard>
                            <h5>BASIC</h5>
                            <h2><span>FREE</span></h2>
                            <p>Self-service support for any platforms.</p>
                            <hr/>
                            <ul>
                                <li><span>Promotion-less use</span></li>
                                <li><span>Community Support</span></li>
                            </ul>
                            <Button>Subscribe</Button>
                        </PriceCard>
                        <PriceCard>
                            <h5>VIP</h5>
                            <h2><sup>$</sup><span>399</span><sub>/mo</sub>
                                <b>(Billed annually)</b>
                            </h2>
                            <p>Includes support for one platforms.</p>
                            <hr/>
                            <ul>
                                <li><span>Includes All Basic Benefits</span></li>
                                <li><span>Dedicated expert assistance</span></li>
                                <li><span>Telegram Chat Service</span></li>
                                <li><span>Priority Support</span></li>
                            </ul>
                            <Button>Subscribe</Button>
                        </PriceCard>
                        <PriceCard>
                            <h5>UNLIMITED</h5>
                            <h2><sup>$</sup><span>999</span><sub>/mo</sub>
                                <b>(Billed annually)</b>
                            </h2>
                            <p>Includes UNLIMITED support for all platforms.</p>
                            <hr/>
                            <ul>
                                <li><span>Includes All VIP Benefits</span></li>
                                <li><span>Support by Top Level Executives</span></li>
                                <li><span>Zoom Call Meetings</span></li>
                                <li><span>Technical and Integration Support</span></li>
                            </ul>
                            <Button>Subscribe</Button>
                        </PriceCard>
                    </div>
                </PricingMain>
            </Container>
        </>
    )
}

const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Container = styled(FlexDiv) `
    margin: 0px auto; width: 1360px; max-width: calc(100% - 40px);
    ${Media.lg} {width: 1100px;}
    ${Media.lg2} {width: 960px;}
`
const PageTop = styled.div `
    margin-bottom: 116px; padding: 210px 0 0 0;
    h1 {text-align: center; background: -webkit-linear-gradient(180deg, #6764ff, #f249f0); display: block; margin: 0px auto 81px; -webkit-background-clip: text; -webkit-text-fill-color: transparent; font-size: 64px; position: relative; width: 680px; line-height: 1.1;  opacity: 0.6; max-width: 100%;}
    h1:after {content: attr(data-text); position: absolute; left: 0; top: 0; text-shadow: 0 0 25px #f249f0; color: transparent; z-index: -1; line-height: 1.1; }
    p {font-size: 18px; color: #fff; line-height: 1.7; margin-bottom: 27px;}
    ${Media.sm} {
        width: 100%;
        h1 {font-size: 50px; margin-bottom: 50px;}
    }
    ${Media.xs} {
        h1 {font-size: 35px;}
    }
`
const PricingMain = styled.div `
    .pricetab {
        display: table; margin: 0 auto;
        span {color: #989898; font-size: 16px; font-weight: 700; line-height: 1; margin-bottom: 27px; display: block;}
        .tabMain {
            border: 2px solid #37fd7d; box-shadow: 0 0 15px #37fd7d; width: 430px; height: 80px; display: flex; padding: 5px; border-radius: 5px; margin: 0px auto 95px; 
            a {width: 50%; text-align: center; display: flex; justify-content: center; align-items: center; font-size: 24px; font-weight: 700; border-radius: 5px;
                &.active {background: #37fd7d; color: #000;}
            } 
        }
    }
    .pricemain {display: flex; align-items: center; gap: 37px;}
    ${Media.lg} {
        .pricemain {
            gap: 20px
        }
    }
    ${Media.md} {
        display: block;
        .pricetab {width: 100%;
            .tabMain {
                width: 100%; height: 70px; margin-bottom: 60px;
                a {font-size: 20px;}
            }
        }
        .pricemain {
            flex-flow: column;
        }
    }
`
const PriceCard = styled.div `
    width: 430px; min-height: 610px; border: 2px solid #2b2b2b; box-shadow: 0 0 20px rgba(255,255,255,0.20); border-radius: 5px; padding: 40px 40px; display: flex; flex-flow: column; margin-bottom: 100px; position: relative; background: #020003;
    &:nth-child(2) {padding: 83px 40px; border: 2px solid #848484;
        &:before {content: "Most Popular"; position: absolute; top: -2px; left: -11px; background: url(${corner}) no-repeat; width: 210px; height: 50px; font-weight: 700; text-align: center; padding: 10px 0 0 0;}
    }
    &:nth-child(3) {
        border: 0; z-index: 2;
        &:before {content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 5px;
            background: linear-gradient(to right, #a849f2, #6764ff);
        }
        &:after {content: "";
            position: absolute;
            z-index: -1;
            top: 2px;
            left: 2px;
            right: 2px;
            bottom: 2px;
            background: #020003;
            border-radius: 5px;
        }
    }
    h5 {color: #fff; font-size: 24px; font-weight: 700; margin: 0 0 17px 0;}
    h2 {font-size: 60px; position: relative;
        span {background: linear-gradient(90deg, #a849f2, #6764ff); -webkit-background-clip: text; color: transparent;}
        sup {font-size: 24px; background: linear-gradient(90deg, #a849f2, #6764ff); -webkit-background-clip: text; color: transparent; top: -1em; font-weight: 700; margin-right: 8px;}
        sub {font-size: 24px; background: linear-gradient(90deg, #a849f2, #6764ff); -webkit-background-clip: text; color: transparent; bottom: -0em; font-weight: 700; margin-left: 8px;}
        b {font-size: 12px; color: #989898; position: absolute; right: 0; top: 17px;}
    }
    p {margin: 13px 0 37px 0; font-size: 14px;}
    hr {border: 0; border-bottom: 1px solid #1a1a1a; margin: 0 0 28px 0;}
    ul {
        padding: 0; margin: 0 0 45px 0;
        li {position: relative; color: rgb(166, 162, 176); font-size: 18px; line-height: 1.7; margin-bottom: 17px; padding-left: 43px; list-style: none;
            &:before {content: ""; width: 18px; height: 36px; position: absolute; left: 0; top: -3px; background: url(${Tick}) center center / 100% no-repeat;}
            span { background: linear-gradient(90deg, rgb(168, 73, 242), rgb(103, 100, 255)) text; color: transparent; font-size: 16px; font-weight: 700;}
        }
    }
    ${Media.lg} {
        width: 300px;
        h2 {font-size: 40px;
            b {top: -7px;}
        }
        ul {
            li {line-height: 1.3; padding-left: 34px;
                &:before {top: -7px;}
            }
        }
    }
    ${Media.md} {
        width: 100%;
    }
`
const FloatingEl = styled.div `
    position: fixed; top: calc(50% + 150px); right: 0; width: 270px; height: 44px; background: linear-gradient(90deg, #6764ff, #f149f0); display: flex; border-radius: 0 0 10px 10px; justify-content: center; align-items: center; color: #000; transform: rotate(90deg); transform-origin: top right; font-weight: 700; box-shadow: 0 0 20px #f249f0; cursor: pointer; z-index: 50;
    span {color: #fff;}
    p {justify-content: center; align-items: center; display: flex;}
    img {margin-left: 16px; margin-top: -4px;}
    .fab {margin-left: 13px; font-size: 24px; transform: rotate(-90deg);}
    ${Media.xs} {
        transform: rotate(90deg) scale(0.7);
    }
`
const Button = styled.a `
    border: 2px solid #37fd7d; box-shadow: 0 0 15px #37fd7d; width: 100%; height: 65px; display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; padding: 5px; border-radius: 5px; font-size: 21px; font-weight: 700; text-align: center; align-items: center; justify-content: center; text-shadow: 0 0 15px #fff; flex-shrink: 0; margin-top: auto; transition: all 0.3s ease-in-out;
    &:hover {box-shadow: 0 0 0 #37fd7d; text-shadow: 0 0 0 #fff;}
`
const Header = styled.div `
    position: absolute; top: 47px; left: 0; right: 0; padding: 0 20px;
`

export default Subscription;