/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/dumpershield.png';
import ds01 from '../assets/images/ds01.jpg';
import ds02 from '../assets/images/ds02.jpg';
import ds03 from '../assets/images/ds03.jpg';
import ds04 from '../assets/images/ds04.jpg';
import ds05 from '../assets/images/ds05.jpg';
import ds06 from '../assets/images/ds06.jpg';






function VestingPlanning({onClose, ClickChange }) {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>01</Gs.Count>
			<h3>Why is your vesting plan important to get right?</h3>

			<p>Your project requires funds in order to launch. To get investors, from VC, launchpads and  community, you need a detailed vesting token plan to ensure your project launch stays healthy.</p>

			<p>Vesting ensures that your early token investors cannot dump your token.  Buy pressure for your token in important to keep your project on track  and poorly planned vesting schedule can cause it to crash.</p>

			<Gs.Hr/>

			<h3 className='red'>Why the current vesting model is broken</h3>
			<p>The issue with the way current vesting models work is that they end up only delaying the pain. However, once the vesting cliff is reached, time after time project token values across the blockchain plummet as all the investors and supporters exit your project creating massive sell pressure. Unfortunately once the price crashes, it can be hard to recover.</p>

			<Gs.Hr/>

			<h3 className='green'>Your next-gen solution to vesting planning</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>Dumper Shield is an innovative decentralized smart vesting solutions, offering a revolutionary approach designed to protect against token price dumping. By leveraging AI learning, it seamlessly merges vesting tokens with the existing circulation supply based on REAL-TIME market appreciation, rather than simply following a predetermined schedule like traditional vesting solutions.</p>
			<p>Protect your token and creating long-term alignment incentives that create liquidity for investors to exit but protect your token. Dumper Shield is the new innovative way to ensure your project has long term health.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='SliderImg' src={ds01}/></div>
					<div className=''><img alt='SliderImg' src={ds02}/></div>
					<div className=''><img alt='SliderImg' src={ds03}/></div>
					<div className=''><img alt='SliderImg' src={ds04}/></div>
					<div className=''><img alt='SliderImg' src={ds05}/></div>
					<div className=''><img alt='SliderImg' src={ds06}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='SliderImg' src={ds01}/></div>
					<div className=''><img alt='SliderImg' src={ds02}/></div>
					<div className=''><img alt='SliderImg' src={ds03}/></div>
					<div className=''><img alt='SliderImg' src={ds04}/></div>
					<div className=''><img alt='SliderImg' src={ds05}/></div>
					<div className=''><img alt='SliderImg' src={ds06}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a target='_blank' href='https://www.dumpershield.exchange/' className='btn' rel="noreferrer">Join Dumper Shield to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}

export default VestingPlanning;
