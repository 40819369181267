/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Gs from '../theme/globalStyles';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Media from '../theme/media-breackpoint';
import Header from '../component/header' 
import VestingPlanning from '../component/VestingPlanning';
import FundraisingLiquidity from '../component/FundraisingLiquidity';
import DexListing from '../component/DexListing';
import Marketing from '../component/Marketing';
import Increasingliquidity from '../component/IncreasingLiquidity';
import StakingComp from '../component/Staking';
import ScalingComp from '../component/Scaling';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Collapsible from 'react-collapsible';


import Logo from '../assets/images/log-text.png';
import LogVideo from '../assets/images/logoVideo.mp4';
import GiftB2 from '../assets/images/money.png';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Home(props) {
  useEffect(() => {
    attemptPlay();
  }, [])
  let query = useQuery();

  AOS.init({
    once: true,
    duration: 1500,
  });
  const [count, setCount] = useState(0);
  const handleTriggerClick = (index) => {
    // Toggle the count state based on the index of the clicked Collapsible
    setCount(count === index ? 0 : index);
  };
  const videoEl = useRef(null); 
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };




  return (
    <>
      <Header />
      <FloatingEl >
        <p><span>FREE</span>&nbsp;white paper AI bot <i className="fab fa-telegram-plane"></i></p>
      </FloatingEl>
      <BannerSec >
        <div className="videoMain">
        {/* autoplay loop muted playsinline */}
          <video controls={false} loop autoPlay  src={LogVideo}  playsInline  muted  ref={videoEl} />
          {/* <video controls={false} loop autoPlay playsInline  muted  ref={videoEl}>
              <source src={LogVideo} type="video/mp4" />
          </video> */}
        </div>
        <img src={Logo} alt='logo' />
      </BannerSec>
      <HomeContent data-aos="fade-up">
        <Container>
          <p>Launching a successful crypto project is more about maintaining buying pressure over selling pressure than about having groundbreaking technology or a unique idea.</p>

          <p>Success can arise organically, as seen in projects like Dogecoin, or it can be crafted through strategic planning, starting with market momentum, smart fundraising, token vesting distribution, promotions and rewards, and exit strategies for investors.</p>

          <p>The current market is flooded with tools such as launchpads, DEXes, farming, and staking that often mimic each other, exacerbating issues like dumping, scams, and runs on the bank.</p>

          <p>Accelerate Ventures program by Quantum Foundation is a revolutionary breakthrough in the crypto space! Introducing innovative solutions to maintain high buying pressure, ensuring sustained success for crypto projects. By addressing industry-wide challenges head-on, it promises to transform the landscape like never before. Get ready for a game-changing revolution!.</p>
        </Container>
      </HomeContent>


      <AccordianSec>
        <Container>
          <h1 data-aos="fade-up">Up to <span>$100,000</span> grant for early projects</h1>
          
          <div className="accoMain" data-aos="fade-up">
            <SideTitle><span>Project Lifestage</span></SideTitle>
            <CollapsibleMain>
              <Collapsible 
                trigger={<h4><span>01</span> AI-powered smart vesting</h4>}
                open={count === 1} handleTriggerClick={() => handleTriggerClick(1)}
              >
                <VestingPlanning/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>02</span> Fundraising-based initial bond offering</h4>}
                open={count === 2} handleTriggerClick={() => handleTriggerClick(2)}
              >
                <FundraisingLiquidity/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>03</span> DEX based self-regulations</h4>}
                open={count === 3} handleTriggerClick={() => handleTriggerClick(3)}
              >
                <DexListing/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>04</span> Ongoing liquidity fundraising offering</h4>}
                open={count === 4} handleTriggerClick={() => handleTriggerClick(4)}
              >
                <Increasingliquidity/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>05</span> Airdrop distribution based anti-dump and scam protection</h4>}
                open={count === 5} handleTriggerClick={() => handleTriggerClick(5)}
              >
                <Marketing/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>06</span> Self-custody token farming</h4>}
                open={count === 6} handleTriggerClick={() => handleTriggerClick(6)}
              >
                <StakingComp/>
              </Collapsible>
              <Collapsible 
                trigger={<h4><span>07</span> ScalePad for ongoing fundraising</h4>}
                open={count === 7} handleTriggerClick={() => handleTriggerClick(7)}
              >
                <ScalingComp/>
              </Collapsible>
            </CollapsibleMain>
            <div className='BtmLink'><a>Seeking help? We've got your back!</a></div>
          </div>
          <ReferBtn>Refer & Win Up to a <span>$1000</span> Reward <img src={GiftB2} alt=''/></ReferBtn>
        </Container>
      </AccordianSec>
    </>
  )
}

const FlexDiv = styled.div`
  display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;
const Container = styled(FlexDiv) `
  margin: 0px auto; width: 1360px; max-width: calc(100% - 40px);
  ${Media.lg} {width: 1100px;}
  ${Media.lg2} {width: 960px;}
`
const BannerSec = styled.div `
  width: 100%; padding: 100px 0; text-align: center;
  .videoMain {mix-blend-mode: lighten; width: 900px; margin: 0 auto -110px; max-width: 100%; z-index: 2; position: relative;
    video {max-width: 100%;
      &::-webkit-media-controls-panel {
        display: none !important;
        opacity: 1 !important;
      }
    }
  }
  ${Media.md2} {
    width: 600px; margin: 0 auto;
    .videoMain {margin: 0 auto -90px;}
  }
  ${Media.sm} {
    width: 300px; margin: 0 auto; padding: 100px 0 50px;
    .videoMain {margin: 0 auto -45px;}
  }
`
const HomeContent = styled.div `
  p {font-size: 18px; color: #fff; margin-bottom: 33px; line-height: 1.6;}
  ${Media.sm} {
    p {font-size: 16px;}
  }
`
const AccordianSec = styled.div `
  padding-top: 75px; margin-bottom: 100px; position: relative;
  
  p {font-size: 18px; margin-bottom: 33px; line-height: 1.6;}
  h1 {color: #fff;  text-align: center; font-size: 36px;
    span {background: linear-gradient(90deg,#a849f2,#6764ff); -webkit-background-clip: text; color: transparent; opacity: 0.6;}
    ${Media.sm} {
      font-size: 30px;
    }
  }

  .accoMain {width: 1360px; margin-top: 120px; margin-left: auto; position: relative; max-width: 100%;
      &:after {}
    .Collapsible__trigger {font-size: 18px; color: #989898; font-weight: 700; padding: 0 30px; height: 70px; display: flex; align-items: center; background: #1a1a1a; border-bottom: 1px solid #000; cursor: pointer;
      &:hover {
        background: #3b3b3b;
        h4 {transform: scale(1.4); color: #fff; }
      }
      &.is-open {
        background: #3b3b3b;
        h4 {transform: scale(1.4); color: #fff; }
        &:after {content: "\f068"; color: #fff;}
      }
      &:after {content: "\f067"; font-family: "Font Awesome 5 Free"; font-weight: 900; color: #989898;}
      h4 {width: 100%; transition: all 0.3s ease-in-out 0s; transform-origin: left;
        span {margin-right: 24px;}
      }
    }
    .BtmLink {
      text-align: right;
      font-size: 12px;
      margin-top: 16px;
      a {color: #fff; font-weight: normal;}
    }
    .Collapsible:last-of-type .Collapsible__trigger {border-bottom: 0 solid #000;}
    ${Media.sm} {
      margin-top: 60px;
      .Collapsible__trigger {
        padding: 0 15px;
        h4 {
          span {margin-right: 10px;}
        }
        &:hover, &.is-open {
          h4 {transform: scale(1.2); color: #fff; }
        }
      }
    }
    ${Media.xs} {
      .Collapsible__trigger {
        h4 {
          font-size: 16px;
          span {margin-right: 5px;}
        }
        &:hover, &.is-open {
          h4 {transform: scale(1.2); color: #fff; }
        }
      }
    }
  }
`
const CollapsibleMain = styled.div `
  overflow: hidden; box-shadow: 0 0 25px rgba(255,255,255,0.15); border-radius: 5px; width: 100%;
`
const SideTitle = styled.div `
  position: absolute; left: -20px; top: 0; opacity: 0.16; font-size: 56px; font-weight: 700; /* transform: rotate(90deg); */ transform: rotate(90deg); transform-origin: left top; color: #fff;
  span {display: block; transform: scale(-1); color: #fff;}
`
const ReferBtn = styled.div `
  font-size: 24px; color: #fff; font-weight: 700; padding: 20px 40px; margin-top: 60px;
  img {vertical-align: top; margin-top: -15px; margin-left: 25px;}
  span {background: linear-gradient(90deg,#a849f2,#6764ff); -webkit-background-clip: text; color: transparent; opacity: 0.6;}
`
const FloatingEl = styled.div `
  position: fixed; top: calc(50% + 150px); right: 0; width: 270px; height: 44px; background: linear-gradient(90deg, #6764ff, #f149f0); display: flex; border-radius: 0 0 10px 10px; justify-content: center; align-items: center; color: #000; transform: rotate(90deg); transform-origin: top right; font-weight: 700; box-shadow: 0 0 20px #f249f0; cursor: pointer; z-index: 50;
  span {color: #fff;}
  p {justify-content: center; align-items: center; display: flex;}
  img {margin-left: 16px; margin-top: -4px;}
  .fab {margin-left: 13px; font-size: 24px;  transform: rotate(-90deg);}
  ${Media.xs} {
    transform: rotate(90deg) scale(0.7);
  }
`

export default Home;