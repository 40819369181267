/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/dexexchange.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import SliderImg00 from '../assets/images/de00.jpg';
import SliderImg01 from '../assets/images/de01.jpg';
import SliderImg02 from '../assets/images/de02.jpg';
import SliderImg03 from '../assets/images/de03.jpg';
import SliderImg04 from '../assets/images/de04.jpg';
import SliderImg05 from '../assets/images/de05.jpg';






function FundraisingLiquidity() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>03</Gs.Count>
			<h3>Why is DEX listing important to your token's health?</h3>

			<p>Buy pressure is the lifeblood of your token. Therefore keeping healthy liquidity and consistent demand for your token is essential  for success.</p>
            <p>Listing your token on exchanges is an important stage that all projects must go through on their path to success.</p>

			<Gs.Hr/>

			<h3 className='red'>Why DEX listing is risky</h3>
			<p>Bad news is bad news, whether its a bear market or a black swan event, uncertainty can cause a run on the bank that leaves your liquidity dry and your project permanently grounded.</p>
			<p>Panic can come from anywhere leading to a massive dumping event. From one whale's dumping action which causes a game-over snowball effect to an unrelated hack in the ecosystem.</p>
            <p>Your token price is vulnerable in so many ways and one event ca turn an otherwise healthy project's dream into a living nightmare.</p>

			<Gs.Hr/>

			<h3 className='green'>Your next-gen DEX listing solution</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>This is a first of its kind self-regulation exchange that provides circuit breakers to protects your project from unpredictable volatility whether it be a bear market, black swan event or anything that could cause a run on the bank.</p>
			<p>With our decentralized protection you can sleep with certainty that your token will not go below the protective circuit breakers you set for your LP.</p>
			<p>It's your money, you should decide how to protect it.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={SliderImg00}/></div>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={SliderImg00}/></div>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://landing.derex.exchange/' target='_blank' className='btn' rel="noreferrer">Join DEREX to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}

export default FundraisingLiquidity;
