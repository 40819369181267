/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/airdropTube.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import SliderImg01 from '../assets/images/at01.jpg';
import SliderImg02 from '../assets/images/at02.jpg';
import SliderImg03 from '../assets/images/at03.jpg';
import SliderImg04 from '../assets/images/at04.jpg';






function MarketingComp() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>04</Gs.Count>
			<h3>Why are airdrops an essential part of your marketing strategy?</h3>

			<p>Giving away tokens through airdrops is an exciting and fast way to grow your community. Go viral, increase your holders and spread the gospel about your amazing project through airdrops. </p>
            <p>Who doesn't like free stuff?.</p>

			<Gs.Hr/>

			<h3 className='red'>Why the current method for airdrops is broken</h3>
			<p>When it comes to Airdrops, it typically your project that is footing the bill.</p>
			<p>Not only are you giving away token from your treasury but it's tough to know who you are giving your precious airdrop tokens to. Are they a genuine holder or is it one person running multiple wallets scams?</p>
            <p>What's more, there's nothing to stop them from dumping right when they get it.  Lastly, all of these airdrops costs you significant gas. This is expensive!</p>

			<Gs.Hr/>

			<h3 className='green'>Your next-gen community airdrop solution</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>Grow your community strategically and sustainably.  Target who you are giving your airdrops to and distribute the token the way you want.</p>
			<p>Airdrop specific whales in a target community in order to bring them over to your project or or reward your long-term holders for their loyalty. Distribute airdrop pro-rata, block by block, or only to long-term holders. The choice is your. Airdrops.Tubes makes it easy to target users strategically and ensure the right people receive your token.</p>
			<p>What' more Airdrops Tube also gives exciting staking alternatives to give your community further incentives to avoid dumping and build lifetime loyalty.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://airdrop.tube/giveaway' target='_blank' className='btn' rel="noreferrer">Join Airdrop to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}
const FlexDiv = styled.div`
	display: flex; align-items: center; flex-wrap:wrap;
`;

const Hr = styled.hr `
	display: block; width: 100%; height: 1px; margin: 0; border-color: #1a1a1a; margin-top: 10px; margin-bottom: 45px;
`
const ContentMain = styled(FlexDiv) `
	padding: 70px 80px 0; position: relative;
	h3 {font-size: 24px; margin-bottom: 33px; width: 100%;
		&.red {color: #ff5757;}
		&.green {color: #37fd7d;}
	}
	.seclogo {margin: 0 0 25px -10px;}
	p {font-size: 18px; color: #989898; width: 100%;
        strong {display: block; font-weight: 700;}
    }
    ul {margin: 0; padding: 0;}
	li {font-size: 18px; color: #989898; width: 100%; margin-bottom: 26px; list-style: none; position: relative; padding-left: 30px;
        &:before { content: ""; width: 10px; height: 10px; background: #989898; position: absolute; border-radius: 15px; top: 6px; left: 0;}
        strong {display: block; font-weight: 700;}
    }
	.slick-track {display: flex;}
	.slider-container {width: 684px; margin: 48px auto 0;}
	.slick-list {overflow: hidden; }
	.slider-for {box-shadow: 0 0 18px rgba(255,255,255,0.5); margin-bottom: 20px; position: relative;
		.slick-prev {position: absolute; top: calc(50% - 15px); left: -38px; width: 30px; height: 30px; background: url(${leftArrow}) no-repeat center; border: 0; text-indent: -1000px; overflow: hidden;}
		.slick-next {position: absolute; top: calc(50% - 15px); right: -38px; width: 30px; height: 30px; background: url(${rightArrow}) no-repeat center; border: 0; text-indent: -1000px; overflow: hidden;}
        img {vertical-align: top;}
	}
	.slider-nav {padding: 0 116px;
		.slick-prev {display: none !important;}
		.slick-next {display: none !important;}
		.slick-slide {padding: 0 5px;
			img {border: 2px solid #3f3f3f; cursor: pointer;}
			&.slick-current img {border: 2px solid #fff;}
		}
	}
	.btnMain {
		display: flex; margin: 98px 0; justify-content: center; width: 100%;
		.btn {height: 65px; width: 684px; border: 2px solid #36fd7c; text-shadow: 0 0 15px rgba(255,255,255,0.8); display: flex; align-items: center; justify-content: center; box-shadow: 0 0 15px #36fd7c; font-size: 21px;
		border-radius: 5px; margin: 0 17px; font-weight: 700;
			&:hover {background-color: #36fd7c; color: #000}
		}
	}
`
const Count = styled.div `
    position: absolute; color: #fff; font-size: 260px; opacity: 0.04; top: -50px; right: 60px; font-weight: 700; line-height: 1;
`



export default MarketingComp;
