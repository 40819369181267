import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import Media from './../theme/media-breackpoint';
import landingBg from '../assets/images/mainbg.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';

var Gs = {}
const FlexDiv = styled.div`
  display: flex; align-items: center; flex-wrap:wrap;
`;
Gs.GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0; 
    font-weight:400 ;
    font-family: "Montserrat", sans-serif;
    color:${(props) => props.theme.Color1};
    background: url(${landingBg}) no-repeat top #000000;
  }  
  button{ outline:none; border:none;}
  img{max-width:100%;}
  .bodySection{} 

  .aos-init {
    ${Media.sm} {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;

    -o-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -webkit-transform: none !important;
    transform: none !important;

    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
    }
  }
`; 
Gs.BlackBg = styled.div `
  
`
Gs.Container = styled.div`
  margin:0 auto; width: 100%; max-width:1360px; padding:0 15px;
  ${Media.lg} {
    max-width:1276px;
  }
  ${Media.lg2} {
    max-width:1170px;
  }
  ${Media.md2} {
    max-width:961px;
  }
  ${Media.sm} {
    max-width:737px;
  }
  ${Media.xs} {
    max-width: -webkit-fill-available;
  }
`;

Gs.Hr = styled.hr `
	display: block; width: 100%; height: 1px; margin: 0; border-color: #1a1a1a; margin-top: 10px; margin-bottom: 45px;
`
Gs.ContentMain = styled(FlexDiv) `
	padding: 70px 80px 0; position: relative;
	h3 {font-size: 24px; margin-bottom: 33px; width: 100%;
		&.red {color: #ff5757;}
		&.green {color: #37fd7d;}
	}
	.seclogo {margin: 0 0 25px -10px;}
	p {font-size: 18px; color: #989898; width: 100%;
        strong {display: block; font-weight: 700;}
    }
    ul {margin: 0; padding: 0;
		li {font-size: 18px; color: #989898; width: 100%; margin-bottom: 26px; list-style: none; position: relative; padding-left: 30px;
			&:before { content: ""; width: 10px; height: 10px; background: #989898; position: absolute; border-radius: 15px; top: 6px; left: 0;}
			strong {display: block; font-weight: 700;}
		}
	}
	.slider-container {width: 684px; margin: 48px auto 0; max-width: calc(100% - 30px);
		.slick-track {display: flex;}
		.slick-list {overflow: hidden; }
		.slider-for {box-shadow: 0 0 18px rgba(255,255,255,0.5); margin-bottom: 20px; position: relative;
			.slick-prev {position: absolute; top: calc(50% - 15px); left: -38px; width: 30px; height: 30px; background: url(${leftArrow}) no-repeat center; border: 0; text-indent: -1000px; overflow: hidden;}
			.slick-next {position: absolute; top: calc(50% - 15px); right: -38px; width: 30px; height: 30px; background: url(${rightArrow}) no-repeat center; border: 0; text-indent: -1000px; overflow: hidden;}
			img {vertical-align: top;}
		}
		.slider-nav {padding: 0 116px;
			.slick-prev {display: none !important;}
			.slick-next {display: none !important;}
			.slick-slide {padding: 0 5px;
				img {border: 2px solid #3f3f3f; cursor: pointer;}
				&.slick-current img {border: 2px solid #fff;}
			}
		}
	}
	.btnMain {
		display: flex; margin: 98px 0; justify-content: center; width: 100%; flex-flow: column; align-items: center; 
		.btn {min-height: 65px; width: 684px; border: 2px solid #36fd7c; text-shadow: 0 0 15px rgba(255,255,255,0.8); display: flex; align-items: center; justify-content: center; box-shadow: 0 0 15px #36fd7c; font-size: 21px; padding: 10px 20px; text-align: center; color: #fff;
		border-radius: 5px; margin: 0 17px; font-weight: 700;
			&:hover {background-color: #36fd7c; color: #000}
		}
    p {flex-grow: 1; width: 684px; max-width: 100%; text-align: right; font-size: 12px; margin-top: 16px;}
	}
	${Media.md} {
		padding: 40px 20px 0;
		.slider-container {
			.slider-nav {padding: 0 60px;}
		}
	}
	${Media.sm} {
		padding: 40px 30px 0;
		.slider-container {
			.slider-nav {padding: 0 00px;}
		}
		p {font-size: 16px; margin-bottom: 23px;}
		h3 {font-size: 20px; margin-bottom: 23px;}
    .btnMain {
      .btn {
        font-size: 18px; padding: 15px 20px;
      }
    }
	}
`
Gs.Count = styled.div `
    position: absolute; color: #fff; font-size: 260px; opacity: 0.04; top: -50px; right: 60px; font-weight: 700; line-height: 1;
`

export default Gs; 

