/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/pdofinance.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import SliderImg01 from '../assets/images/pdo01.jpg';
import SliderImg02 from '../assets/images/pdo02.jpg';
import SliderImg03 from '../assets/images/pdo03.jpg';
import SliderImg04 from '../assets/images/pdo04.jpg';






function ScalingComp() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
            <Gs.Count>07</Gs.Count>
			<h3>How do scale after your raised during your TGE?</h3>
			<p>Growth is essential for your project. Sometimes this means you need to raise more funds to take your project to the next level.</p>
            <p>How do you increase token value and keep fundraising sustainably once your project  already launched a TGE?.</p>

			<Gs.Hr/>

			<h3 className='red'>The challenge with scaling</h3>
            <p>Raising funds post TGE is difficult.</p>
			<p>You can sell your treasury token through a DEX, but that creates a very negative signal to your community and can cause mistrust or worse, a sell-off.</p>
			<p>You could also sell to a VC below the market price, but that's not much better than just selling your token on the market.</p>
            <p>Many projects who need to raise additional funds are faced with this conondrum and must choose their own potentially project crashing poison.</p>
            

			<Gs.Hr/>

			<h3 className='green'>Your next-gen solution that scales your project</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>Use your treasury reserve to create a PDO offering and encourage your community to buy the token slippage free from your PDO instead of the DEX.</p>
			<p>The proceeds from the sale  will be used as follow:  1/2 of the funds are used to buy the token from your DEX pool (which will increase the token price) to pair with the other half. This creates an LP token that YOU will own.</p>
			<p>But what about the need for new funds?.</p>
            <p>If your project need some cash to cover salaries or other expenses, you can send less money to the pool for liquidity and keep some as alternative for fundraising.</p>
            <p>This is a win for all.</p>
            <ul>
                <li>A new method to continuously raise funds all year long.</li>
                <li>A new method to leverage your current project's market trading volume to increase token appreciation.</li>
                <li>A new method to gain sustainable liquidity without depending on liquidity providers.</li>
            </ul>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://pdo.finance/' target='_blank' className='btn' rel="noreferrer">Join PDO Finance to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}
export default ScalingComp;
