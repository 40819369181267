/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/ibopad.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import ds01 from '../assets/images/ibopad1.png';
import ds02 from '../assets/images/ibopad2.png';
import ds03 from '../assets/images/ibopad3.png';
import ds04 from '../assets/images/ibopad4.png';
import ds05 from '../assets/images/ds05.jpg';
import ds06 from '../assets/images/ds06.jpg';






function FundraisingLiquidity() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>02</Gs.Count>
			<h3>Why is liquidity fundraising essential?</h3>

			<p>When a project raises money, 50-80% goes to the liquidity pool that powers your entire ecosystem. Without liquidity investors won't invest and a project can't gain traction. </p>

			<Gs.Hr/>

			<h3 className='red'>Why the current model for launching liquidity pools is broken</h3>
			<p>The way it works right now, kickstarting a liquidity pools is a necessary evil. Investors are faced with the possibilities of rug pulling, scams, and all types of other bad actors.</p>
			<p> Even in the best scenarios, liquidity pools aren't protected from a dreaded bear market or unexpected negative project news.</p>

			<Gs.Hr/>

			<h3 className='green'>Your next-gen solution to launching liquidity pools</h3>
			<img className='seclogo' src={Seclogo} alt='IBO Pad'/>
			<p>Make the investment process safer and more exciting. IBO changes the game by providing investors with a security guarantee through our innovative decentralized circuit breakers that ensure their investment has a secure floor price.</p>
			<p>IBO protects their initial investment while leaving them free to take advantage of all the upside.</p>
			<p>It's a win-win for everyone.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={ds01}/></div>
					<div className=''><img alt='Slider' src={ds02}/></div>
					<div className=''><img alt='Slider' src={ds03}/></div>
					<div className=''><img alt='Slider' src={ds04}/></div>
					{/* <div className=''><img alt='Slider' src={ds05}/></div>
					<div className=''><img alt='Slider' src={ds06}/></div> */}
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={ds01}/></div>
					<div className=''><img alt='Slider' src={ds02}/></div>
					<div className=''><img alt='Slider' src={ds03}/></div>
					<div className=''><img alt='Slider' src={ds04}/></div>
					{/* <div className=''><img alt='Slider' src={ds05}/></div>
					<div className=''><img alt='Slider' src={ds06}/></div> */}
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://ibo.derex.exchange/' target='_blank' className='btn' rel="noreferrer">Join IBOpad to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}


export default FundraisingLiquidity;
