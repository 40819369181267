 

export const theme = (isDarkTheme) => {
    return {
        // Background 
        BG1:  isDarkTheme ? '#0b0c0f' : '#0b0c0f',
        BG2: isDarkTheme ? '#00f02b' : '#00f02b',
        BG2Hover: isDarkTheme ? '#00d426' : '#00d426',

        // Color 
        Color1: isDarkTheme ? '#ffffff' : '#ffffff',
        Color2: isDarkTheme ? '#8e9195' : '#8e9195',
        
      
    }
}