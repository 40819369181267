import React, { useState } from 'react'; 
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Footer from './component/Footer' 
import Home from './pages/home' 
import Subscription from './pages/subscription' 
import Gs from './theme/globalStyles';
import { ThemeProvider } from 'styled-components'
import { theme } from './theme/theme' 


function App() {   

  const [isDark,setDarkTheme] = useState(true);
  const selectedTheme = theme(isDark);

  function setTheme(flag){
    setDarkTheme(flag);    
  }

  return (
    <Router> 
    <ThemeProvider theme={selectedTheme}>
      <section className='bodySection clearfix'>
        <Gs.GlobalStyle />  
          
          <Switch>
            <Route path="/" exact> <Home isDarkTheme={isDark}  />  </Route> 
            <Route path="/subscription" exact> <Subscription isDarkTheme={isDark}  />  </Route> 
          </Switch> 
          <Footer isDarkTheme={isDark} setTheme={setTheme}  />
        </section>
    </ThemeProvider>
    </Router> 
  );
}

export default App;