/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import Slider from 'react-slick';

import Seclogo from '../assets/images/lpinsure.png';
import leftArrow from '../assets/images/leftArrow.png';
import rightArrow from '../assets/images/rightArrow.png';
import SliderImg00 from '../assets/images/lpi00.jpg';
import SliderImg01 from '../assets/images/lpi01.jpg';
import SliderImg02 from '../assets/images/lpi02.jpg';
import SliderImg03 from '../assets/images/lpi03.jpg';
import SliderImg04 from '../assets/images/lpi04.jpg';
import SliderImg05 from '../assets/images/lpi05.jpg';






function Increasingliquidity() {
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);
	let sliderRef1 = useRef(null);
	let sliderRef2 = useRef(null);

	useEffect(() => {
		setNav1(sliderRef1);
		setNav2(sliderRef2);
	}, []);
	
	return (
		<Gs.ContentMain>
			<Gs.Count>05</Gs.Count>
			<h3>Why your project needs to increase liquidity?</h3>

			<p>Your project has matured to the point where it requires more liquidity. This liquidity will help reduce slippage and makes it easier for investors to trade your token. The current method that projects use to attract liquidity providers is through offering APY with farming strategies.</p>

			<Gs.Hr/>

			<h3 className='red'>Why APY farming incentives are misaligned</h3>
			<p>There are two main methods to obtain liquidity through offering APY to investors:</p>
			<p><strong>Locked farming</strong>
It's great for the project but hurts the LPs as investors don't want to be stuck in a risky lock up with nowhere to go.</p>
            <p><strong>Unlocked farming</strong>
If anything goes wrong or if there is any unexpected bad news, a few investors can flip on you and dump immediately. This could crash your pool, hurting your project and your other investors.</p>
            <p>A massive selloff can cause your project to go from healthy to untrustworthy overnight.</p>

			<Gs.Hr/>

			<h3 className='green'>Your next-gen solution to increasing liquidity</h3>
			<img className='seclogo' src={Seclogo} alt='dumper shield'/>
			<p>LP Insure is a decentralized insurance solution for liquidity providers that utilizes bond tokens to protect against losses resulting from token dumping. It automatically recovers losses up to the original bond value, and any remaining funds in the insurance reserve are used to buy back project tokens and burn them to benefit the token holder community.</p>
			<p>LPInsurance provides protection for your investors to give them peace of mind while providing your projects much needed liquidity, With this protection you can give your investors security through all types of markets.</p>
			<p>Yes even during a long cold crypto winter.</p>
			<p>WIth staking features built in, investors will have no reason to take their LP elsewhere. Investors and you can sit back and relax with peace of mind while the APY rolls in.</p>

		<div className="slider-container">
			<div className='slider-for'>
				<Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)}>
					<div className=''><img alt='Slider' src={SliderImg00}/></div>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
			<div className='slider-nav'>
				<Slider
					asNavFor={nav1}
					ref={slider => (sliderRef2 = slider)}
					slidesToShow={4}
					swipeToSlide={true}
					focusOnSelect={true}
				>
					<div className=''><img alt='Slider' src={SliderImg00}/></div>
					<div className=''><img alt='Slider' src={SliderImg01}/></div>
					<div className=''><img alt='Slider' src={SliderImg02}/></div>
					<div className=''><img alt='Slider' src={SliderImg03}/></div>
					<div className=''><img alt='Slider' src={SliderImg04}/></div>
					<div className=''><img alt='Slider' src={SliderImg05}/></div>
				</Slider>
			</div>
		</div>
		<div className='btnMain'>
			<a href='https://lp.insure/' target='_blank' className='btn' rel="noreferrer">Join LP Insure to Apply for Grant</a>
			<p>Seeking help? We've got your back!</p>
		</div>
		</Gs.ContentMain>
	)

}


export default Increasingliquidity;
