/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Gs from '../theme/globalStyles'
import styled from 'styled-components'
import { Link, NavLink ,useLocation } from 'react-router-dom'
import Media from '../theme/media-breackpoint'
import fLogo from '../assets/images/fLogo.png';

import linkedin from '../assets/images/linkedin-in.svg'
import twitter from '../assets/images/x-twitter.svg'
import facebook from '../assets/images/facebook.svg'


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Footer(props) {
    return (
        <FooterMain>
            <div className='FooterLeft'>
                <span>Powered by</span>
                <a href="https://quantum.foundation/" target='_blank'  rel="noreferrer"><img src={fLogo} alt='logo'/></a>
            </div>
            <div className='FooterCenter'>
                {/* <span>Seeking help? We've got your back!</span> */}
                <div className='FMCenter'>
                    <a target='_blank'><img src={twitter}/></a>
                    <a target='_blank' ><img src={linkedin}/></a>
                    <a><img src={facebook}/></a>
                </div>
            </div>
            <div className='FooterRight'>
                <a href='https://www.jotform.com/form/240767041036148#preview' target='_blank' rel="noreferrer">Opportunity for Biz Dev & Listing Managers</a>
            </div>
        </FooterMain>
    )   
}
const FlexDiv = styled.div`
    display: flex; align-items: center; justify-content:center; flex-wrap:wrap;
`;

const FooterMain = styled(FlexDiv)`
    justify-content: space-between; background-color: rgb(0, 0, 0); height: 100px; margin: 0px; border-top: 1px solid rgb(30, 33, 39); width: 100%; padding: 0px 30px;
    .FooterLeft {display: flex; width: 33.33%; align-items: center;
        span {
            margin: 0px 15px 0px 0px;
            font-size: 12px;
            font-weight: bold;
            color: rgb(154, 154, 154);
            text-align: left;
        }
    }
    .FooterCenter { width: 33.33%; text-align: center;
        span {font-size: 12px; }
        .FMCenter {text-align: center;
            a {margin: 0 8px;
                img {max-width: 16px; height: 16px; object-fit: contain; max-width: inherit; }
                &:hover {opacity: 0.7;}
            }
        }
    }
    .FooterRight { width: 33.33%; text-align: right;
        a {
            font-size: 14px;
            font-weight: normal;
            text-align: left;
            background: linear-gradient(90deg,#a849f2,#6764ff); -webkit-background-clip: text; color: transparent;
            &:hover {color: #fff;}
        }
    }
    ${Media.lg2}{
        padding: 18px 15px;
    }
    ${Media.md2}{
        flex-flow: column;
        .FMRight {margin-top: 20px;}
    }
    ${Media.sm}{
        height: auto;
        .FooterRight {width: 100%; text-align: center; margin-bottom: 15px;}
        .FooterCenter {width: 100%; text-align: center; margin-bottom: 10px;
            .FMCenter {text-align: center;
                a {margin: 0 7px;
                    img {max-width: 16px; height: 16px; object-fit: contain; max-width: inherit; }
                    &:hover {opacity: 0.7;}
                }
            }
        }
        .FooterLeft { justify-content: center; padding-top: 15px; width: 100%;
            p {flex-flow: column;  border-right: 0; justify-content: flex-end;  align-items: flex-start;
                span {margin: -14px 0 5px;}
            }
        }
    }
`;

export default Footer;
